import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function PaymentSuccess() {
  const [responseId, setResponseId] = useState("");
  const [responseState, setResponseState] = useState([]); // Not sure if this is being used anywhere, can be removed if not needed
  const { amount } = useParams(); // Extracting 'amt' from URL parameters
  const { token } = useParams(); // Extracting 'amt' from URL parameters
  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => reject(new Error("Script loading failed"));
      document.body.appendChild(script);
    });
  };

  const createRazorpayOrder = async (amount) => {
    try {
      let data = JSON.stringify({
        amount: amount * 100, // Razorpay expects the amount in paise
        currency: "INR",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.rubyshoping.online/api/razorpay",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios.request(config);
      handleRazorpayScreen(response.data.amount); // Assuming the response amount is in paise
    } catch (error) {
      console.error("Error during order creation:", error);
      alert("Failed to create Razorpay order. Please try again.");
    }
  };

  const handleRazorpayScreen = async (amount) => {
    try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Some error at razorpay screen loading");
        window.location.href = "https://qbit99.com/Dashboard";
        return;
      }

      const options = {
        key: "rzp_live_lqIxNnKgsACP64",
        amount: amount,
        currency: "INR",
        name: "R",
        handler: function (response) {
          setResponseId(response.razorpay_payment_id);
          fetchPaymentDetails(response.razorpay_payment_id); // Automatically fetch payment details
        },
        prefill: {
          name: "r",
          email: "info@rubyshoping.online",
        },
        theme: {
          color: "#0dcaf0",
        },
        // This event is triggered when the user closes the Razorpay modal without completing the payment
        modal: {
          ondismiss: function () {
            window.location.href = "https://qbit99.com/Dashboard";
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      window.location.href = "https://qbit99.com/Dashboard";
      console.error("Error opening Razorpay:", error);
    }
  };

  const fetchPaymentDetails = async (paymentId) => {
    try {
      await axios.get(
        `https://api.rubyshoping.online/api/payment/${paymentId}`
      );
      const data = {
        price: amount, // replace with actual amount
        req_no: paymentId, // replace with actual id
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Ensure the content type is set properly
        },
      };

      await axios
        .post("https://api.qbit99.com/api/inrdeposit", data, config)
        .then((response) => {
          console.log("Data:", response.data);
        })
        .catch((error) => {
          console.error(
            "Error:",
            error.response ? error.response.data : error.message
          );
        });

      window.location.href = "https://qbit99.com/Dashboard";
    } catch (error) {
      console.error("Error occurred while fetching payment details:", error);
      window.location.href = "https://qbit99.com/Dashboard";
      alert("Payment verification failed, please try again.");
    }
  };

  useEffect(() => {
    if (amount) {
      createRazorpayOrder(amount); // Use the 'amt' from URL parameters
    }
  }, [amount]); // Run this effect when 'amt' is available

  return (
    <div>{/* Optionally, display some loading or success message here */}</div>
  );
}

export default PaymentSuccess;
